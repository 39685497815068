import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de 4 a 12 cm de diàmetre, ondulat, carnós, sec i de tacte vellutat, de color blanc groguenc a quasi crema i marge enrotllat. Per davall del capell presenta agullons de color blanc un poc decurrents pel peu, molt fràgils. Presenten un peu curt, irregular, gruixut i sol ser de color groc però més pàl·lid que el capell. Les espores són rodones, un poc anguloses, de color blanc crema en massa de 6-8 x 5-7 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      